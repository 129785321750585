<template>
  <div>
    <v-card
      :loading="carregandoBuscar"
      elevation="1"
    >
      <v-toolbar
        flat
        dense
        color="grey lighten-3"
      >
        <v-toolbar-title># {{ pontoCaptura.id || '--' }}</v-toolbar-title>

        <template v-slot:extension>
          <v-tabs
            v-model="tab"
            align-with-title
            slider-color="red"
          >
            <v-tabs-slider color="info" />
            <v-tab>
              Dados
            </v-tab>
            <v-tab
              :disabled="!pontoCaptura.id || !$temPermissao('ponto-captura-listagem-cameras') || !$temPermissao('camera-listagem-cameras')"
            >
              Câmeras
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-divider />

      <v-tabs-items v-model="tab">
        <v-tab-item :key="0">
          <validation-observer ref="observer">
            <v-form
              class="mt-4"
              autocomplete="off"
            >
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="descricao"
                    >
                      <v-text-field
                        v-model="pontoNovo.descricao"
                        outlined
                        dense
                        hide-details="auto"
                        :disabled="carregandoBuscar"
                        label="Descrição"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="grupos_ponto_captura_id"
                    >
                      <v-select
                        v-model="pontoNovo.grupos_ponto_captura_id"
                        :items="grupos"
                        :loading="carregandoBuscarGrupos"
                        item-text="descricao"
                        item-value="id"
                        outlined
                        dense
                        label="Grupo"
                        hide-details="auto"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="endereco_ip"
                    >
                      <v-text-field
                        v-model="pontoNovo.endereco_ip"
                        outlined
                        dense
                        hide-details="auto"
                        :disabled="carregandoBuscar"
                        label="Endereço IP"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="logradouro"
                    >
                      <v-text-field
                        v-model="pontoNovo.logradouro"
                        outlined
                        dense
                        hide-details="auto"
                        :disabled="carregandoBuscar"
                        label="Logradouro"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="complemento"
                    >
                      <v-text-field
                        v-model="pontoNovo.complemento"
                        outlined
                        dense
                        hide-details="auto"
                        :disabled="carregandoBuscar"
                        label="Complemento"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="numero"
                    >
                      <v-text-field
                        v-model="pontoNovo.numero"
                        v-mask="'######'"
                        outlined
                        dense
                        hide-details="auto"
                        :disabled="carregandoBuscar"
                        label="Número"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="cep"
                    >
                      <v-text-field
                        v-model="pontoNovo.cep"
                        v-mask="'#####-###'"
                        outlined
                        dense
                        hide-details="auto"
                        :disabled="carregandoBuscar"
                        label="CEP"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="6"
                    md="3"
                  >
                    <autocomplete-estados
                      v-model="estadoId"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="cidade_id"
                    >
                      <autocomplete-cidades
                        v-model="pontoNovo.cidade_id"
                        :estado-id="estadoId"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="latitude"
                    >
                      <v-text-field
                        v-model="pontoNovo.latitude"
                        outlined
                        dense
                        hide-details="auto"
                        :disabled="carregandoBuscar"
                        label="Latitude"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="longitude"
                    >
                      <v-text-field
                        v-model="pontoNovo.longitude"
                        outlined
                        dense
                        hide-details="auto"
                        :disabled="carregandoBuscar"
                        label="Longitude"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                  >
                    <v-btn
                      icon
                      @click="mostrarMapa = true"
                    >
                      <v-icon>
                        mdi-map-marker-radius
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    md="3"
                  >
                    <v-switch
                      v-model="pontoNovo.status"
                      class="mt-0 pt-0"
                      :disabled="carregandoBuscar"
                      :true-value="1"
                      :false-value="0"
                      label="Ativo"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </validation-observer>
          <v-divider />
          <v-row v-if="$temPermissao('ponto-captura-alteracao') || $temPermissao('ponto-captura-insercao')">
            <v-col
              cols="12"
              class="text-left pa-6"
            >
              <btn-salvar
                :carregando="carregandoSalvar"
                @click="$emit('salvar', pontoNovo)"
              />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item :key="1">
          <pontos-captura-cameras
            :ponto-captura="pontoCaptura"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <dialog-mapa-selecionar-coordenada
      :mostrar="mostrarMapa"
      @coordenada-selecionada="coordenadaSelecionada"
      @fechar="mostrarMapa = false"
    />
  </div>
</template>

<script>
  import _ from 'lodash'
  import apiGruposPontosCaptura from '@/api/grupos-pontos-captura'

  export default {
    components: {
      AutocompleteEstados: () => import('@/components/input/InputAutocompleteEstados'),
      AutocompleteCidades: () => import('@/components/input/InputAutocompleteCidades'),
      PontosCapturaCameras: () => import('@/views/pages/pontos-captura/cameras/PontosCapturaCameras'),
      DialogMapaSelecionarCoordenada: () => import('@/components/dialog/DialogMapaSelecionarCoordenada'),
    },

    props: {
      validacaoFormulario: {
        type: Object,
        default: () => { return {} },
      },
      carregandoSalvar: {
        type: Boolean,
        default: false,
      },
      carregandoBuscar: {
        type: Boolean,
        default: false,
      },
      pontoCaptura: {
        type: Object,
        default: () => { return {} },
      },
    },

    data () {
      return {
        tab: 0,
        estadoId: null,
        carregandoBuscarGrupos: false,
        mostrarMapa: false,
        grupos: [],
        cameras: [],
        pontoNovo: {
          status: 1,
          latitude: null,
          longitude: null,
        },
      }
    },

    watch: {
      validacaoFormulario (val) {
        if (!val) return

        return this.$refs.observer.setErrors(val)
      },

      pontoCaptura: {
        immediate: true,
        handler (val) {
          if (_.isEmpty(val)) return false

          this.pontoNovo = val
          this.pontoNovo.cidade_id = val.endereco.cidade_id
          this.estadoId = val.endereco.cidade.estado_id
        },
      },
    },

    mounted () {
      this.buscarGrupos()
    },

    methods: {
      async buscarGrupos () {
        try {
          this.carregandoBuscarGrupos = true
          const resposta = await apiGruposPontosCaptura.listar({ por_pagina: 1000, pagina: 1 })
          this.grupos = resposta.data.grupos_ponto_captura
        } catch (e) {
          console.log(e)
        } finally {
          this.carregandoBuscarGrupos = false
        }
      },

      coordenadaSelecionada (coordenada) {
        this.pontoNovo.latitude = coordenada.lat.toString()
        this.pontoNovo.longitude = coordenada.lng.toString()
        this.mostrarMapa = false
      },

    },

  }
</script>
